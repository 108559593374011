<template>
  <vue-navigation-bar :options="navbarOptions" />
</template>
<script>

  import { modulesRoles } from '@/data/modulesRoles'

  export default {
    data () {
      return {
        navbarOptions: {
          elementId: 'main-navbar',
          isUsingVueRouter: true,
          mobileBreakpoint: 992,
          brandImagePath: './',
          brandImageAltText: 'brand-image',
          collapseButtonOpenColor: '#661c23',
          collapseButtonCloseColor: '#661c23',
          showBrandImageInMobilePopup: true,
          ariaLabelMainNav: 'Main Navigation',
          tooltipAnimationType: 'shift-away',
          menuOptionsLeft: modulesRoles,
        },
      }
    },
    created () {
      const role = JSON.parse(atob(localStorage.getItem('uid'))).rol
      for (var x = 0; x < this.navbarOptions.menuOptionsLeft.length; x++) {
        const opts = this.navbarOptions.menuOptionsLeft[x].subMenuOptions
        this.navbarOptions.menuOptionsLeft[x].subMenuOptions = opts.filter(opt => {
          return opt.role.indexOf(role) !== -1
        })
      }
    },
  }
</script>

<style lang="scss">
.vnb {
  &__menu-options {
    &__option {
      &__link {
        &:focus {
          outline: none;
          border: 1px solid none;
        }

        &:hover {
          color: #0081ff;

          .vnb__menu-options__option__arrow {
            fill: #0081ff;
          }
        }

        &__icon {
          svg {
            fill: #0081ff !important;
          }
        }
      }
    }
  }

  &__sub-menu-options {
    &__option {
      &__link {
        &:focus {
          outline: none;
          border: 1px solid none;
        }

        color: #000 !important;

        &:hover {
          color: #0081ff !important;
        }
      }
    }
  }
  background: rgb(240 240 240) !important;
  border-radius: 6px !important;
}

.vnb__collapse-button {
  &:focus {
    border: 1px solid none;
    outline: none;
  }

  &:after {
    content: "\F035C";
    font-size: 25px;
    font-weight: 600;
    font-family: "Material Design Icons";
  }

  svg {
    display: none !important;
  }
}

.vnb__popup {
  max-height: 80vh;
  overflow-x: hidden;
  overflow-y: scroll !important;

  .vnb__popup__top__close-button {
    &:focus {
      border: 1px solid none;
      outline: none;
    }

    top: 20px;
    right: -33px;

    svg {
      fill: #000 !important;
    }
  }
}
</style>
